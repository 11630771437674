import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const BackButton = styled(Link)`
  background-color: transparent;
  border: 0 none;
  font-size: 18px;
`;
export const Logo = styled.img.attrs(() => ({
  src: "/logo-tecimob.svg"
}))`
  height: 14px;
`;
