import React from "react";
import { MdArrowBack } from "react-icons/md";
import { Wrapper, BackButton, Logo } from "./styles";

const Header = () => {
  return (
    <Wrapper>
      <BackButton to="/">
        <MdArrowBack />
      </BackButton>
      <Logo />
    </Wrapper>
  );
};

export default Header;
