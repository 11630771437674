import React from "react";
import SignupForm from "components/SignupForm";
import Header from "./components/Header";
import SEO from "components/Seo";
import { Wrapper } from "./styles";
import { GlobalStyle } from "config-styles";

const Cadastro = () => {
  return (
    <Wrapper>
      <SEO title="Cadastro, Sem contrato de fidelidade e sem surpresas" />
      <Header />
      <SignupForm />
      <GlobalStyle />
    </Wrapper>
  );
};

export default Cadastro;
